<template>
    <div id="configLevel">
        <div class="configLevel_title">
            <div>升降级策略</div>
            <span>间夜数指已预定人身份入住的间夜数，同住身份的间夜数无效，全天房算1个间夜，钟点房算0.5个间夜，特殊房算0间夜</span>
        </div>
        <div class="configLevel_levelInfo">
            <div>会员等级
                <span v-if="levelInfo.level != 10">{{ levelInfo.level ? levelInfo.level : 0 }}级</span>
                <span v-if="levelInfo.level == 10">特级</span>
            </div>
            <div v-if="levelInfo.level == 10 || 1">
                <div>降级策略<span>不再降级</span></div>
                <div v-if="levelInfo.level == 10">升级策略<span>不再升级</span></div>
            </div>

            <!-- <div v-if="levelInfo.level == 1">
                <div>降级策略<span>不再降级</span></div>
                <div>升级策略<span>免费升级</span></div>
            </div> -->
            <div v-if="levelInfo.level != 10" class="strategy">
                <div class="strategy_item"  v-if="levelInfo.level != 1">
                    <div class="strategy_item_title">降级策略</div>
                    <div class="strategy_item_content">
                        <div style="margin-bottom: 20px;">
                            有效期内入住间夜数 >=
                            <el-input v-model="levelInfo.relegation_room_num" style="width: 80px;" size="small"></el-input>保级,
                            未达到入住间夜数，按照已入住间夜数匹配会员等级
                        </div>
                    </div>
                </div>

                <div class="strategy_item">
                    <div class="strategy_item_title">升级策略</div>
                    <div class="strategy_item_content">
                        <!-- <div class="strategy_item_content_item">
                            <div class="strategy_item_content_item_left">按间夜升级 </div>

                            <div class="strategy_item_content_item_right">
                                <div class="strategy_item_content_item_right_item">
                                    有效期内入住达到
                                    <el-input v-model="input" style="width: 80px;" size="small"></el-input>
                                    间夜数时，在订单完成后升为此级别
                                </div>
                                <div class="strategy_item_content_item_right_item"  v-if="levelInfo.level != 1">
                                    除OTA渠道订单，其它各渠道订单累计>=
                                    <el-input v-model="input" style="width: 80px;" size="small"></el-input>
                                    间夜数时，在订单完成后升为此级别
                                </div>
                            </div>
                        </div> -->

                        <div class="strategy_item_content_item"  v-if="levelInfo.level != 1">
                            <div class="strategy_item_content_item_left">按消费金额升级</div>
                            <div class="strategy_item_content_item_right">
                                <div class="strategy_item_content_item_right_item">
                                    有效期内消费达到
                                    <el-input v-model="levelInfo.total_consume" style="width: 80px;"
                                        size="small"></el-input>
                                    升为此级别
                                </div>
                            </div>
                        </div>

                        <div class="strategy_item_content_item"  v-if="levelInfo.level != 1">
                            <div class="strategy_item_content_item_left">按充值金额升级</div>
                            <div class="strategy_item_content_item_right">
                                <div class="strategy_item_content_item_right_item">
                                    有效期内充值达到
                                    <el-input v-model="levelInfo.total_recharge" style="width: 80px;"
                                        size="small"></el-input>
                                    升为此级别
                                </div>
                            </div>
                        </div>

                        <div class="strategy_item_content_item"  v-if="levelInfo.level != 1">
                            <div class="strategy_item_content_item_left">绿色通道</div>
                            <div class="strategy_item_content_item_right">
                                <div class="strategy_item_content_item_right_item">
                                    邀请
                                    <el-input v-model="levelInfo.share_user_num" style="width: 80px;"
                                        size="small"></el-input>
                                    位新用户入住一间夜升为此级别
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="configLevel_title">
            <div>配置权益</div>
        </div>
        <div class="configLevel_equity">
            <div class="configLevel_equity_checks">
                <div class="configLevel_equity_checks_items">
                    <el-checkbox v-model="levelInfo.is_free_deposit">免押入住</el-checkbox>
                    <!-- <el-checkbox v-model="checked">房型升级</el-checkbox> -->
                    <!-- <el-checkbox v-model="levelInfo.is_fixed">固定等级</el-checkbox> -->
                    <el-checkbox v-model="levelInfo.is_exclusive_peripheral">专属外设</el-checkbox>
                    <el-checkbox v-model="levelInfo.is_super_privileges">超级礼遇</el-checkbox>
                    <el-checkbox v-model="levelInfo.birthday_gift">生日礼包</el-checkbox>
                </div>
            </div>
            <div class="configLevel_equity_fills">
                <div>
                    <span>延时退房</span>
                    <el-input v-model="levelInfo.late_check_out" style="width: 100px;" size="small"
                        placeholder="请填写时间"></el-input>
                </div>
                <div>
                    <span>订房折扣</span>
                    <el-input v-model="levelInfo.room_discount" style="width: 100px;" size="small"
                        placeholder="请填写折扣"></el-input>
                </div>
                <div>
                    <span>关联订房</span>
                    <el-input v-model="levelInfo.room_num" style="width: 100px;" size="small"
                        placeholder="请填写间数"></el-input>
                </div>
                <div>
                    <span>积分比例</span>
                    <el-input v-model="levelInfo.integration_conversion" style="width: 100px;" size="small"
                        placeholder="赠送积分比例"></el-input>
                </div>
                <div>
                    <span>房型升级</span>
                    <el-input v-model="levelInfo.room_upgrade" style="width: 100px;" size="small"
                        placeholder="请填写次数"></el-input>次
                </div>
            </div>
        </div>
        <div class="configLevel_button">
            <el-button size="small">取消</el-button>
            <el-button size="small" type="primary" @click="submit">保存</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            input: '',

            levelId: null,//等级ID
            levelInfo: {
                name: '--',
                room_discount: 0,
                integration_conversion: 0,
                room_upgrade: 0,
                late_check_out: 0,
                room_num: 0,

                is_free_deposit: 0,//是否免押入住
                birthday_gift: 0,//生日礼包
                is_exclusive_peripheral: 0,//专属设备
                is_super_privileges: 0,//超级礼遇
                is_fixed: 0,//是否固定等级

                total_recharge: 0,//累计充值
                total_consume: 0,//累计消费
                share_user_num:0,//拉新会员数量
                relegation_room_num:0,//保级间夜

            },//会员详情
        }
    },
    created() {
        this.levelId = this.$route.query.id;
        this.getLevelInfo()
    },
    mounted() { },
    methods: {
        submit() {
            let data = {
                id: this.levelInfo.id,
                name: this.levelInfo.name,
                room_discount: this.levelInfo.room_discount,
                integration_conversion: this.levelInfo.integration_conversion,
                room_upgrade: this.levelInfo.room_upgrade,
                late_check_out: this.levelInfo.late_check_out,
                room_num: this.levelInfo.room_num,

                is_free_deposit: this.levelInfo.is_free_deposit ? 1 : 0,//是否免押入住
                birthday_gift: this.levelInfo.birthday_gift ? 1 : 0,//生日礼包
                is_exclusive_peripheral: this.levelInfo.is_exclusive_peripheral ? 1 : 0,//专属设备
                is_super_privileges: this.levelInfo.is_super_privileges ? 1 : 0,//超级礼遇
                is_fixed: this.levelInfo.is_fixed ? 1 : 0,//是否固定等级

                total_recharge: this.levelInfo.total_recharge,//累计充值
                total_consume: this.levelInfo.total_consume,//累计消费
                share_user_num: this.levelInfo.share_user_num,//拉新会员数量
                relegation_room_num:this.levelInfo.relegation_room_num,//保级间夜

            }
            console.log('编辑会员等级详情data', data);

            this.my_request('admin/member/grade', data, 'PUT').then(res => {
                console.log('编辑会员等级详情结果', res);
                let { code } = res.data
                if (200 == code) {
                    this.$message.success('保存成功！')
                    this.getLevelInfo()
                } else {
                    this.$message.error('保存失败')
                }
            })
        },
        // 获取会员等级详情结果
        getLevelInfo() {
            let data = {
                id: this.levelId
            }
            console.log('获取会员等级data', data);
            this.my_request('admin/member/grade/show', data, 'GET').then(res => {
                console.log('获取会员等级详情结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.levelInfo = data
                    this.levelInfo.is_free_deposit = this.levelInfo.is_free_deposit == 0 ? false : true//是否免押入住
                    this.levelInfo.birthday_gift = this.levelInfo.birthday_gift == 0 ? false : true//生日礼包
                    this.levelInfo.is_exclusive_peripheral = this.levelInfo.is_exclusive_peripheral == 0 ? false : true//专属设备
                    this.levelInfo.is_super_privileges = this.levelInfo.is_super_privileges == 0 ? false : true//超级礼遇
                    this.levelInfo.is_fixed = this.levelInfo.is_fixed == 0 ? false : true//是否固定等级
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
#configLevel {
    padding: 10px 20px;

    .configLevel_equity {
        padding: 20px 30px;

        .el-checkbox {
            margin-right: 100px;
        }

        .configLevel_equity_fills {
            display: flex;
            font-size: 14px;
            margin: 30px 0;

            div {
                margin-right: 20px;

                span {
                    margin-right: 10px;
                }
            }
        }

    }

    .configLevel_button {
        width: 800px;
        display: flex;
        justify-content: flex-end;
    }

    .configLevel_title {
        div {
            font-size: 18px;
            line-height: 35px;
        }

        span {
            font-size: 14px;
            color: #999999;
        }
    }

    .configLevel_levelInfo {
        font-size: 16px;
        padding: 30px;

        div {
            margin-bottom: 20px;
        }

        span {
            margin-left: 20px;
        }

        .strategy {

            div {
                margin-bottom: 0px;
            }

            .strategy_item {
                margin-bottom: 0px;

                .strategy_item_title {}

                .strategy_item_content {
                    margin: 10px 0px 10px 40px;

                    .strategy_item_content_item {
                        display: flex;

                        .strategy_item_content_item_left {
                            width: 150px;
                            line-height: 32px;

                        }

                        .strategy_item_content_item_right {
                            .strategy_item_content_item_right_item {
                                margin-bottom: 20px;

                            }
                        }
                    }
                }

                .el-input {
                    margin: 0 5px;
                }

                // div {
                //     margin: 10px 0px 10px 40px;
                // }

                // span {
                //     margin-left: 0px;
                //     display: inline-block;
                //     width: 150px;
                // }
            }
        }
    }
}
</style>